import React from 'react'
import { Link, useParams } from 'react-router-dom'

import { Button, GlobalStyle, Stack, Text } from '@procsea/design-system'

import useDocumentTitle from 'src/hooks/useDocumentTitle'
import lock from 'src/images/lock.svg'
import { LocaleParams } from 'src/types'

import { AuthContainer, AuthMainCard, AuthMainCardImage } from '../common'
import AuthHeader from '../common/AuthHeader'

const ResetPasswordDone = () => {
  useDocumentTitle(gettext('Reset Password'))

  const { locale } = useParams<LocaleParams>()

  return (
    <AuthContainer>
      <GlobalStyle styledTheme="light" />

      <AuthHeader data-e2e="reset-password-done-header-link" />

      <AuthMainCard shadow="middle" spacing="xlarge">
        <Stack spacing="large">
          <AuthMainCardImage src={lock} />

          <Text size="xxxlarge" variant="secondary">
            {gettext('Password reset complete')}
          </Text>

          <Text variant="black">
            {gettext(
              'Your password has been successfully reset, you can now log in using your new password.'
            )}
          </Text>

          <Link to={`/${locale}/accounts/login`}>
            <Button fluid data-e2e="reset-password-done-login-link" variant="primary">
              {gettext('Go to the login page')}
            </Button>
          </Link>
        </Stack>
      </AuthMainCard>
    </AuthContainer>
  )
}

export default ResetPasswordDone
